<template>
  <div>
    <v-layout column wrap>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h5 class="font-weight-light">LIST OF PARTNERS</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">
                ID
              </th>
              <th class="text-uppercase" v-if="position==='ADMIN'">
                Edit
              </th>
              <th class="text-uppercase" v-if="position==='ADMIN'">
                Add Access to Portal
              </th>
              <th class="text-center text-uppercase">
                Created By
              </th>
              <th class="text-uppercase">
                Merchant Percentage
              </th>
              <th class="text-uppercase">
                Date Started
              </th>
              <th class="text-center text-uppercase">
                Category
              </th>
              <th class="text-center text-uppercase">
                Name of Partner
              </th>
              <th class="text-center text-uppercase">
                School
              </th>
              <th class="text-center text-uppercase">
                Email
              </th>
              <th class="text-center text-uppercase">
                Name of Representative
              </th>
              <th class="text-center text-uppercase">
                Representative Position
              </th>
              <th class="text-center text-uppercase">
                Representative Contact #
              </th>
              <th class="text-center text-uppercase">
                Name of Representative 2
              </th>
              <th class="text-center text-uppercase">
                Representative Position 2
              </th>
              <th class="text-center text-uppercase">
                Representative Contact # 2
              </th>
              <th class="text-center text-uppercase">
                Name of Representative 3
              </th>
              <th class="text-center text-uppercase">
                Representative Position 3
              </th>
              <th class="text-center text-uppercase">
                Representative Contact # 3
              </th>

            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in district_list"
              :key="item.id"
            >
              <td class="text-center">{{ item.id }}</td>
              <td class="text-center" v-if="position==='ADMIN'">
                <div>
                  <v-icon
                    class="mr-2"
                    color="info"
                    @click="edit_details(item)"
                  >
                    {{icons.mdiPencil}}
                  </v-icon>
                </div>
              </td>
              <td class="text-center" v-if="position==='ADMIN'">
                <div>
                  <v-icon
                    class="mr-2"
                    color="warning"
                    @click="add_access(item)"
                  >
                    {{icons.mdiPlus}}
                  </v-icon>
                </div>
              </td>
              <td class="text-center">{{ item.user.name }}</td>
              <td class="text-center">{{ item.merchant_percentage }}</td>
              <td class="text-center">{{ item.date_registered }}</td>
              <td class="text-center">{{ item.partnership_category }}</td>
              <td class="text-center">{{ item.name_of_partner }}</td>
              <td class="text-center">{{ item.school }}</td>
              <td class="text-center">{{ item.email_address }}</td>
              <td class="text-center">{{ item.name_of_partner_representative }}</td>
              <td class="text-center">{{ item.name_of_partner_representative_position }}</td>
              <td class="text-center">{{ item.name_of_partner_representative_contact }}</td>
              <td class="text-center">{{ item.name_of_partner_representative2 }}</td>
              <td class="text-center">{{ item.name_of_partner_representative_position2 }}</td>
              <td class="text-center">{{ item.name_of_partner_representative_contact2 }}</td>
              <td class="text-center">{{ item.name_of_partner_representative3 }}</td>
              <td class="text-center">{{ item.name_of_partner_representative_position3 }}</td>
              <td class="text-center">{{ item.name_of_partner_representative_contact3 }}</td>

            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-layout>
    <v-dialog v-model="edit_details_dialog" persistent max-width="80%">
      <new-partnership :is_edit="true" :key="key" :value="selected_partner"
                       v-on:data="on_update_change_type"></new-partnership>
      <v-btn color="error" @click="edit_details_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="add_access_dialog" persistent max-width="80%">
      <v-card>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-card
            flat
          >
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">USER's DETAILS</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col
                md="4"
                cols="12"
              ></v-col>
              <v-row>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="id_no"
                    label="ID NO"
                    dense
                    outlined
                    :rules="rules.default_max_100_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="password"
                    label="PASSWORD"
                    dense
                    outlined
                    :rules="rules.default_max_100_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"

            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click="save_staff_details"
              v-if="!saving"
            >
              Save changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-form>
      </v-card>
      <v-btn color="error" @click="add_access_dialog = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiEyeCircle, mdiPencil, mdiPlus} from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";
  import NewPartnership from '@/views/user_interface/register/NewPartnership'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
      NewPartnership,
    },
    data() {
      return {
        officers_dialog: false,
        saving: false,
        add_access_dialog: false,
        edit_details_dialog: false,

        alert: false,
        alert_message: '',

        district_list: [],
        type_of_club: 'All',

        name_of_district: '',
        district_id: 0,
        key: 0,
        address: '',
        password: '',
        id_no: '',
        selected_partner: {},
        // icons
        icons: {
          mdiEyeCircle,
          mdiPencil,
          mdiPlus,
        },
      }
    },
    mounted() {
      this.load_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('list_of_partnerships', ['get_list_of_partners']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['register_user', 'is_user_already_encoded', 'list_of_user', 'update_user_details']),
      edit_details(item) {
        this.key++
        this.selected_partner = item
        if (this.position === 'ADMIN') {
          this.edit_details_dialog = true
        } else {
          this.change_snackbar({
            show: true,
            color: 'error',
            text: 'ONLY ADMIN CAN EDIT',
          })
        }
      },
      add_access(item) {
        this.key++
        this.selected_partner = item
        if (this.position === 'ADMIN') {
          this.add_access_dialog = true
        } else {
          this.change_snackbar({
            show: true,
            color: 'error',
            text: 'ONLY ADMIN CAN EDIT',
          })
        }
      },
      load_data() {
        this.get_list_of_partners()
          .then(response => {
            this.district_list = response.data
          })
      },
      on_update_change_type() {
        this.edit_details_dialog = false
        this.load_data()
      },
      save_staff_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('name', this.selected_partner.name_of_partner);
          data.append('password', this.password);
          data.append('id_no', this.id_no);
          data.append('positions', 'MERCHANT PARTNER');
          data.append('district_id', '');
          data.append('merchant_id', this.selected_partner.id);
          this.is_user_already_encoded(data)
            .then(response => {
              if (response.data == 1) {
                this.alert = true
                this.alert_message = 'User Already Encoded!'
                this.saving = false
              } else {
                this.register_user(data)
                  .then(response => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: response.data,
                    })
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
              }
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    }
  }
</script>
